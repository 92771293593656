import React, { Component } from 'react';
import styles from './HeroSection.module.scss';

class HeroSection extends Component {
  render() {
    const { title, subtitle, svg, renderTitle } = this.props;

    return (
      <div className={styles.heroSection}>
        <div className={styles.innerContainer}>
          <div className={styles.titleSection}>
            <div className={styles.title}>
              <span className={'brand-color'}>~/</span>
              {renderTitle ? renderTitle() : <h1>{title}</h1>}
            </div>
            <div>{subtitle ? <h2>{subtitle}</h2> : null}</div>
          </div>

          <div className={styles.svgSection}>{svg}</div>
        </div>
      </div>
    );
  }
}

export default HeroSection;
