import clsx from 'clsx';
import React from 'react';
import styles from './HomeHero.module.scss';
import ReactRotatingText from '../RotatingText/ReactRotatingText';
import ShootingStars from './ShootingStars';
import { Link } from 'gatsby';

const HomeHero = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>
        Hi, I'm Raj <span className={styles.handEmoji}>👋</span>
      </h1>

      <div className={clsx(styles.welcome, styles.text)}>
        Welcome to my{' '}
        <Link to={'/2020/06/thinking-of-blog-as-digital-garden/'}>digital garden</Link>. 🌱
      </div>

      <div className={clsx(styles.intro, styles.text)}>
        I am a product engineer with expertise in Elixir & JavaScript ecosystem. I work at{' '}
        <a href={'https://invideo.io/ai'} target={'_blank'}>
          invideo
        </a>
        , where I spend my time building the best web based video creation tool.
      </div>

      <div className={styles.text}>
        Here, I write about my{' '}
        <ReactRotatingText
          items={['ideas', 'projects', 'learnings']}
          customClassNameForOutput={styles.highlight}
        />
        .
      </div>

      <ShootingStars />
    </div>
  );
};

export default HomeHero;
