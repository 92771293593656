import React, { useState } from 'react';

const ProjectPoints = ({ node }) => {
  const NUM_OF_POINTS_TO_SHOW = 3;
  const [pointsToShow, setPointsToShow] = useState(NUM_OF_POINTS_TO_SHOW);
  const [isExpanded, setIsExpanded] = useState(false);

  const points = node.points;

  if (!points) {
    return null;
  }

  const canPointsFit = points.length <= NUM_OF_POINTS_TO_SHOW;

  const showMore = () => {
    if (pointsToShow === NUM_OF_POINTS_TO_SHOW) {
      setPointsToShow(points.length);
      setIsExpanded(true);
    } else {
      setPointsToShow(NUM_OF_POINTS_TO_SHOW);
      setIsExpanded(false);
    }
  };

  return (
    <div className={'projectPoints'}>
      <ul>
        {points.slice(0, pointsToShow).map((point, index) => (
          <li dangerouslySetInnerHTML={{ __html: point }} key={index} />
        ))}
      </ul>

      {!canPointsFit && (
        <a onClick={showMore} className={'showMoreLink'}>
          {isExpanded ? 'Show Less' : 'Show More'}
        </a>
      )}
    </div>
  );
};

export default ProjectPoints;
