import React from 'react';

const FooterWave = function () {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className={'footerwave'}>
      <path
        fill-opacity="0.9"
        d="M0,128L40,154.7C80,181,160,235,240,213.3C320,192,400,96,480,90.7C560,85,640,171,720,208C800,245,880,235,960,197.3C1040,160,1120,96,1200,64C1280,32,1360,32,1400,32L1440,32L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
      ></path>
    </svg>
  );
};

export default FooterWave;
