import React, { Component, Fragment } from 'react';
import './BlogPostComponents.scss';

class Heading extends Component {
  render() {
    return (
      <Fragment>
        <h2 className={'blogPostHeading'}>{this.props.children}</h2>
        <hr className={'blogPostHeadingLine'} />
      </Fragment>
    );
  }
}

export default Heading;
