import React, { Component } from 'react';
import { Link } from 'gatsby';

function ListItem(props) {
  return (
    <li className={'navBarLinkListItem'}>
      <Link title={props.title} to={props.to} activeClassName={'nav-link-active'}>
        {props.children}
      </Link>
      <a className={'nav-link-fake-hidden'}>{props.children}</a>
    </li>
  );
}

class NavLinks extends Component {
  render() {
    return (
      <ul className={'ul_nav_bar'}>
        <ListItem title={'Blogs'} to={'/blogs/'} pathName={this.props.pathName}>
          Blogs
        </ListItem>
        <ListItem title={'About'} to={'/about/'} pathName={this.props.pathName}>
          About Me
        </ListItem>
        <ListItem title={'Projects'} to={'/projects/'} pathName={this.props.pathName}>
          Projects
        </ListItem>
        <ListItem
          title={'Compendium'}
          to={'https://compendium.rajrajhans.com'}
          pathName={this.props.pathName}
        >
          Compendium
        </ListItem>
      </ul>
    );
  }
}

export default NavLinks;
