import React, { Component } from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO';
import styles from './index.module.scss';
import BlogPostBox from '../components/BlogPost/BlogPostBox';
import HomeHero from '../components/HomeHero/HomeHero';
import { OutlinedButton } from '../components/common/OutlinedButton/OutlinedButton';
import clsx from 'clsx';

class Index extends Component {
  render() {
    const blogs = this.props.data.allMdx.nodes;

    return (
      <Layout location={this.props.location} customClass={styles.container}>
        <SEO title={'Home'} />

        <HomeHero />

        <div className={styles.blogsHeader}>
          <h2>Latest Posts</h2>
          <Link to={'/blogs'} className={styles.noTextDecoration}>
            <OutlinedButton className={styles.viewAllBtn}>
              <span>View All</span>
              <span className={styles.blogsCount}>({this.props.data.allMdx.totalCount})</span>
            </OutlinedButton>
          </Link>
        </div>

        <div>
          {blogs.map((blog, id) => (
            <BlogPostBox blog={blog} key={id} />
          ))}
        </div>

        <Link to={'/blogs'} className={styles.noTextDecoration}>
          <OutlinedButton className={clsx(styles.viewAllBtn, styles.bottomBtn)}>
            <span>View All</span>
            <span className={styles.blogsCount}>({this.props.data.allMdx.totalCount})</span>
          </OutlinedButton>
        </Link>
      </Layout>
    );
  }
}

export default Index;

export const pageQuery = graphql`
  query IndexQuery {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }, limit: 21) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          description
          date(formatString: "MMMM Do, YYYY")
          mainTag
          featuredImage {
            childImageSharp {
              fixed(width: 330, quality: 90) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;
