import React, { useState } from 'react';
import Layout from '../components/Layout/Layout';
import HeroSection from '../components/HeroSection/HeroSection';
import './about.scss';
import { AboutSVG, AboutBlogSVG } from '../static/svgs';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import { ABOUT_PAGE_SEO_DESC, PROFILE_IMAGE_URL } from '../utils/constants';
import { getRandomNumber } from '../utils/common';

function About(props) {
  const [profileImageId, setProfileImageId] = useState(() => getRandomNumber({ min: 1, max: 15 }));

  const updateProfileImageId = () => {
    setProfileImageId(getRandomNumber({ min: 1, max: 15 }));
  };

  const renderHeroSection = () => <HeroSection title={'About'} svg={<AboutSVG />} />;

  return (
    <Layout
      location={props.location}
      customClass={'aboutWrapper'}
      renderOutsideContainer={renderHeroSection}
    >
      <SEO title={'About'} description={ABOUT_PAGE_SEO_DESC} url={'/about'} />

      <div className={'content-container'}>
        <div className="aboutPageContainer">
          <div className="aboutTitle">
            <div className="aboutTitleText">
              <div className="aboutTitleTextHeading">
                <h2>About Me</h2>
              </div>

              <div className="aboutTitleTextContent">
                <p>
                  Hey there, I'm Raj. I'm a product engineer who enjoys developing well-designed,
                  responsive, and functional web applications.
                </p>

                <p>
                  I am proficient in JavaScript, TypeScript and the React ecosystem. I also have
                  experience in developing REST API backends using Flask (Python), and Spring Boot
                  (Java). I can leverage the power of AWS and Azure Cloud Services to deploy web
                  applications that can scale. I'm always up for learning a new technology and
                  exploring how it solves common problems.
                </p>

                <p>
                  I'm currently working at InVideo, where I spend my time building the best web
                  based video editor to make it easy for anyone wanting to create videos.
                  Previously, I have worked as an Open Source Fellow with the AWS Amplify team as
                  part of the MLH Fellowship.
                </p>
              </div>
            </div>

            <div className="aboutTitlePhoto">
              <img
                src={PROFILE_IMAGE_URL(profileImageId)}
                alt={'Raj Rajhans'}
                onClick={updateProfileImageId}
              />
            </div>
          </div>

          <div className="aboutBlog">
            <div className="aboutBlogPhoto">
              <AboutBlogSVG />
            </div>

            <div className="aboutBlogText">
              <div className="aboutBlogTextHeading">
                <h2>About the Blog</h2>
              </div>

              <div className="aboutBlogTextContent">
                <p>Oppenheimer once said, “The best way to learn is to teach”.</p>
                <p>
                  Blogging is a way to document one’s learnings. If you haven’t, go watch{' '}
                  <a
                    href={'https://www.youtube.com/watch?v=RVKofRN1dyI'}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                    title={"GaryVee's Youtube video"}
                  >
                    {' '}
                    Gary Vaynerchuk’s Document, Don’t Create video.
                  </a>
                </p>

                <p>
                  Developers are creators, and documenting the creating process only improves the
                  developer’s skills. Writing about something solidifies the learning process. It’s
                  easy to say you’ve done some reading on X, but if you have a blog post and a
                  GitHub project to back up, it really makes it more believable. Not just that, it
                  has the potential to help someone. A small contribution is always better than no
                  contribution.
                </p>

                <p>
                  <a href={'/2020/06/thinking-of-blog-as-digital-garden/'}>
                    I like to think of my blog as more of a digital garden, rather than a
                    traditional blog.
                  </a>{' '}
                  The aim is to document the projects, ideas, and learnings.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default About;

export const query = graphql`
  query AboutImageQuery {
    allFile(filter: { name: { eq: "raj-profile-pic-1" } }) {
      nodes {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;
