import React from 'react';
import ProjectPoints from './ProjectPoints';
import GithubIcon from '../Icons/social-icons/Github';
import TextIcon from '../Icons/TextIcon';
import GlobeIcon from '../Icons/GlobeIcon';
import ExternalLinkIcon from '../Icons/ExternalLink';

const ProjectBox = ({ node }) => {
  return (
    <div className="projectBox" key={node.id}>
      <div className="projectImageContainer">
        <div className="projectImage">
          <a
            className={'nostylelink'}
            rel={'noopener noreferrer'}
            target={'_blank'}
            href={node.website ? node.website : node.casestudy ? node.casestudy : node.github}
          >
            <img src={node.image.publicURL} alt={node.title} />

            {node.website && (
              <div className="projectImageOverlay">
                <div className="projectImageIcon">
                  <ExternalLinkIcon />
                </div>
              </div>
            )}
          </a>
        </div>
      </div>

      <div className="projectDetails">
        <div className="projectTitle">
          <a
            className={'nostylelink'}
            rel={'noopener noreferrer'}
            target={'_blank'}
            href={node.casestudy ? node.casestudy : node.website ? node.website : node.github}
          >
            <h2>{node.title}</h2>
          </a>
        </div>

        <div className="projectSubtitle">{node.subtitle}</div>

        <hr className={'projectLine'} />

        <p>
          <div
            className="projectDescription"
            dangerouslySetInnerHTML={{ __html: node.description }}
          />

          <ProjectPoints node={node} />
        </p>

        <div className="projectLinks">
          {node.github ? (
            <a
              href={node.github}
              title={'View Code on GitHub'}
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              <div className={'project-box-link'}>
                <div className="heroBlogTag project-box-link-btn">
                  <GithubIcon width={20} height={20} />
                  <span>GitHub </span>
                </div>
              </div>
            </a>
          ) : null}

          {node.casestudy ? (
            <a
              href={node.casestudy}
              title={'View Case Study'}
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              <div className={'project-box-link'}>
                <div className="heroBlogTag project-box-link-btn">
                  <TextIcon width={20} height={20} />
                  <span>Case Study</span>
                </div>
              </div>
            </a>
          ) : null}

          {node.website ? (
            <a
              href={node.website}
              title={'View Live Version'}
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              <div className={'project-box-link'}>
                <div className="heroBlogTag project-box-link-btn">
                  <GlobeIcon width={20} height={20} />
                  <span>View Demo</span>
                </div>
              </div>
            </a>
          ) : null}
        </div>

        <div className="projectTags">
          {node.tags.map((tag) => (
            <span>#{tag}</span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectBox;
