import React from 'react';
import styles from './tagPage.module.scss';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout/Layout';
import SEO from '../../components/SEO';
import InternalLink from '../../components/Links/InternalLink';
import InternalLinkDown from '../../components/Links/InternalLinkDown';
import HeroSection from '../../components/HeroSection/HeroSection';
import BlogPostBox from '../../components/BlogPost/BlogPostBox';

const Tags = ({ pageContext, data, location }) => {
  const { tag } = pageContext;
  const { edges, totalCount } = data.allMdx;
  const siteTitle = data.site.siteMetadata.title;

  const renderTitle = () => (
    <h1>
      {`${totalCount} post${totalCount === 1 ? '' : 's'} tagged "`}
      <span className="brand-color">{`${tag}`}</span>"
    </h1>
  );

  const renderHeroSection = () => (
    <div className={styles.heroSection}>
      <HeroSection renderTitle={renderTitle} />
    </div>
  );

  return (
    <Layout location={location} title={siteTitle} renderOutsideContainer={renderHeroSection}>
      <SEO title={tag} />

      <div className="homeBlogBoxesCntnr">
        {edges.map((node, id) => {
          const blog = node.node;
          return (
            <div className={styles.blogs} key={id}>
              <InternalLinkDown link={blog.fields.slug}>
                <BlogPostBox blog={blog} id={id} />
              </InternalLinkDown>
            </div>
          );
        })}
      </div>

      <InternalLink link={'/archives'}>
        <div className={'blogPrevCntnr allTagsLinkCntnr'}>
          <svg
            className={'blogPrevSVG'}
            stroke="#3355ff"
            fill="#3355ff"
            stroke-width="0"
            viewBox="0 0 448 512"
            height="1em"
            width="1em"
          >
            <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path>
          </svg>
          All Tags
        </div>
      </InternalLink>
    </Layout>
  );
};

export default Tags;

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            description
            date(formatString: "MMMM Do, YYYY")
            mainTag
            featuredImage {
              childImageSharp {
                fixed(width: 330) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
