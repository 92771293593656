import React, { Component } from 'react';
import Logo from '../Header/Logo';
import NavLinks from '../Header/NavLinks';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import './Footer.scss';
import FooterWave from '../Icons/FooterWave';
import SocialLinks from '../Header/SocialLinks';

class Footer extends Component {
  render() {
    const currentTheme = this.props.theme ?? 'light';
    const bgColor = currentTheme === 'light' ? '#3355FF' : '#001681';

    return (
      <footer className="footer" style={{ background: <FooterWave /> }}>
        <div className="container">
          <div className="logo">
            <AniLink cover bg={bgColor} duration={0.75} to="/" title={'Raj Rajhans'}>
              <Logo />
            </AniLink>
          </div>
          <div className="navlinks footer-nav-links-container">
            <NavLinks />
          </div>
          <div className="navlinks footer-social-links-container">
            <SocialLinks />
          </div>
          <p className="footer-copyright">
            Copyright &copy; Raj Rajhans {new Date().getFullYear()}
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
