import React, { Component } from 'react';
import Layout from '../components/Layout/Layout';
import './projects.scss';
import styles from './projects.module.scss';
import HeroSection from '../components/HeroSection/HeroSection';
import { ProjectsSVG } from '../static/svgs';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import { PROJECTS_PAGE_SEO_DESC } from '../utils/constants';
import ProjectBox from '../components/ProjectsPage/ProjectBox';
import ProjectBoxVertical from '../components/ProjectsPage/ProjectBoxVertical';

class Projects extends Component {
  data = this.props.data.allProjectsJson.nodes;
  webdevdata = this.props.data.allWebdevJson.nodes;

  renderHeroSection = () => <HeroSection title={'Projects'} svg={<ProjectsSVG />} />;

  renderProjects = () => (
    <>
      <div className="content-container projectBox_container">
        {this.data.map((node) => (
          <ProjectBox node={node} key={node.id} />
        ))}
      </div>

      <div className={styles.freelanceSectionContainer}>
        <div className={styles.freelanceSectionText}>
          <h2>Freelance Website Development Projects</h2>

          <p>
            Back in my college days, I took on some freelancing projects for designing & developing
            SEO optimized websites for small businesses. Along with that, I was also responsible for
            the websites of most of the events that happened on campus. Most of them are inactive by
            now, but here's a glimpse -
          </p>
        </div>

        <div className={styles.verticalProjectsContainer}>
          {this.webdevdata.map((node, index) => (
            <ProjectBoxVertical node={node} key={node.id} />
          ))}
        </div>
      </div>
    </>
  );

  render() {
    return (
      <Layout
        location={this.props.location}
        customClass={'projectsWrapper'}
        renderOutsideContainer={() => (
          <>
            {this.renderHeroSection()}
            {this.renderProjects()}
          </>
        )}
      >
        <SEO title={'Projects'} description={PROJECTS_PAGE_SEO_DESC} url={'/projects'} />
      </Layout>
    );
  }
}

export default Projects;

export const query = graphql`
  query PROJECTS_PAGE_QUERY {
    allProjectsJson {
      nodes {
        id
        title
        subtitle
        description
        points
        image {
          publicURL
        }
        website
        github
        casestudy
        tags
      }
    }
    allWebdevJson {
      nodes {
        id
        title
        description
        image {
          publicURL
        }
        website
        tags
      }
    }
  }
`;
